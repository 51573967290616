<template>
  <v-card @click="confirmaProducto()">
    <v-card-title>
      {{ producto.nombre_producto }}
    </v-card-title>
    <v-card-text>
        <div><img :src="producto.img!=''?producto.img:'/img/sinfoto.png'" width="100"></div>
      <div>{{ producto.codigo }}</div>
      <div>Precio {{ formatoMonedaChile(producto.precio_venta) }}</div>
      <div>{{ producto.clasificacion }}</div>
      <div>
        {{ producto.marca }} {{ producto.modelo }} {{ producto.version }}
      </div>
      <div>{{ producto.agno }}</div>
    </v-card-text>
  </v-card>
  <v-dialog v-model="dialog" max-width="900" width="80%" persistent>
    <v-layout style="height: 25px">
      <v-system-bar>
        <v-icon
          class="me-2"
          icon="mdi-information-variant"
        ></v-icon>

        <span>Información de producto</span>

        <v-spacer></v-spacer>
        <v-btn class="ms-2" icon="mdi-close" variant="text"
          @click="cerrarProducto()"></v-btn>
      </v-system-bar>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-container>
          <descProducto :id="producto.id"></descProducto>
        </v-container>
      </v-card-text>
      <v-card-action> </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
import descProducto from "@/components/producto/viewproducto.vue";
import { formatoMonedaChile } from "@/helpers/funcionesEstandard";
export default {
  data: () => ({
    dialog: false,
    mensajeDialog: false,
    formatoMonedaChile: formatoMonedaChile,
  }),
  props: {
    producto: Object,
  },
  components: {
    descProducto,
  },
  methods: {
    confirmaProducto: function () {
      /*this.mensajeDialog =
        '¿Desea agregar el producto "' +
        this.producto.nombre_producto +
        '" al carro de compras?';*/
      this.dialog = true;
    },
    cerrarProducto: function () {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>