<template>
  <v-app>
    <v-main>
      
      <textoSuperior></textoSuperior>
      <menuSuperior :user="user"></menuSuperior>
      <v-container align="center" class="border-col">
        <!-- Contenido de tu aplicación aquí -->
        <v-sheet class="mt-2">
          
        
          <v-card class="elevation-6" max-width="1300">
            <v-fab class="mt-5 mr-2"
            color="success"
            icon="mdi-cart"
            location="bottom end"
            size="small"
            absolute
            offset>
            <v-icon>mdi-cart</v-icon>
            <v-badge
              v-if="countCart"
              :content="countCart"
              :value="countCart > 0"
              color="primary"
              overlap
              offset-x="-10"
              offset-y="10"
            >
              
            </v-badge>
          </v-fab>  
            <router-view
              @validarMensaje="validarMensaje"
              @setUsuario="setUsuario"
              @setFullLoading="setFullLoading"
              @SalirSistema="SalirSistema"
            />
          </v-card>
        </v-sheet>
      </v-container>
    </v-main>
    <v-dialog
      persistent
      width="auto"
      max-width="70%"
      v-if="dialogEvalMessage"
      v-model="dialogEvalMessage"
    >
      <v-card class="elevation-6 mt-10">
        <v-toolbar :title="msg.title"
          >{{ msg.code }} {{ msg.number ? " : " + msg.number : "" }}</v-toolbar
        >
        <v-divider class="mt-3"></v-divider>
        <v-card-text>
          {{ msg.text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="center">
              <v-btn variant="outlined" class="bg-blue" @click="cerrar"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import textoSuperior from "@/components/front/mensaje_superior.vue";
import menuSuperior from "@/components/front/menu_superior.vue";

export default {
  name: "App",

  data: () => ({
    dialogEvalMessage: false,
    fullLoading: false,
    textoFullLoading: "",
    entorno: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    countCart: 3,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null,
    },
    user: {
      usuarioId: 6,
      options: [
        { codigo: "/quienessomos", nombre: "Quienes Somos" },
        { codigo: "/mision", nombre: "Misión" },
        { codigo: "/vision", nombre: "Visión" },
        { codigo: "/listadoProducto", nombre: "Productos" },
      ],
    },
  }),
  components: {
    textoSuperior,
    menuSuperior,
  },
  methods: {
    setFullLoading: async function (visible, texto) {
      if (visible) {
        this.textoFullLoading = texto;
      }
      this.fullLoading = visible;
    },
    validarMensaje: async function (msgAjax) {
      if (typeof msgAjax == "object") {
        this.msg.number = msgAjax.error;
        this.msg.code = msgAjax.code;
        this.msg.title = msgAjax.title;
        this.msg.text = msgAjax.message;
      } else {
        this.msg.number = "0000";
        this.msg.code = "0000";
        this.msg.title = "ERROR INESPERADO";
        this.msg.text =
          "El mensaje de error no pudo ser interpretado correctamente, el sistema se cerrará. Por favor volver a ingresar.";
      }
      this.dialogEvalMessage = true;
    },
    cerrar: async function () {
      this.dialogEvalMessage = false;
      if (this.msg.number == 1000) {
        this.SalirSistema();
      }
    },
    SalirSistema: function () {
      this.user = {
        token: null,
        name: null,
        usuarioId: null,
      };
      this.setUsuario(this.user);
      this.dialogEvalMessage = false;
      this.fullLoading = false;
      this.textoFullLoading = "";
      this.$router.push("/");
    },
  },
};
</script>

<style>
.border-col {
  padding: 3px;
}
</style>