<template>
  <v-container>
    <destacados></destacados>
    <Nuevosagregados></Nuevosagregados>
  </v-container>
</template>

<script>
import Destacados from "@/components/producto/destacados.vue";
import Nuevosagregados from "@/components/producto/nuevosagregados.vue";

export default {
  data: () => ({}),
  components: {
    Destacados,
    Nuevosagregados,
  },
};
</script>

<style>
</style>