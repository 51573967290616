<template>
  <v-form ref="formNew" @submit.prevent="submitNew">
    <v-row class="pa-3">
      <v-col cols="3" sm="2">
        <formNumero labelSet="ID" :readonlySet="true" ref="ID"></formNumero>
      </v-col>
      <v-col cols="9" sm="4">
        <formTexto
          labelSet="Código"
          :maxLengthSet="30"
          ref="codigo"
          :requeridoSet="true"
        ></formTexto>
      </v-col>
      <v-col cols="12" sm="6">
        <formTexto
          labelSet="Nombre"
          :maxLengthSet="100"
          ref="nombre"
          :requeridoSet="true"
        ></formTexto>
      </v-col>
      <v-col cols="12" sm="4">
        <formComboBox
          labelSet="Clasificación"
          :itemsSet="clasificaciones"
          ref="clasificacion"
        ></formComboBox>
      </v-col>
      <v-col cols="12" sm="8">
        <formMultiplecombobox
          labelSet="Compatibilidad"
          :itemsSet="vehiculos"
          ref="compatibilidad"
        ></formMultiplecombobox>
      </v-col>
      <v-col cols="12" sm="6">
        <formNumero
          labelSet="Precio Venta"
          ref="precio_venta"
          :requeridoSet="true"
          :preIconoSet="mdi - currency - usd"
        ></formNumero>
      </v-col>
      <v-col cols="12" sm="6">
        <formarchivo :maxFilesSet="1" 
          MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
          multipleSet="false"
          labelSet="Imagen de producto" 
          acceptSet="image/*"
          ref="imagen"></formarchivo>
      </v-col>
      <v-col cols="12" sm="6">
        <formFechaHora
          MensajeAyudaSet="Si desea destacar, indicar hasta que fecha, si no, dejar en blanco"
          labelSet="Destacado" 
          ref="destacado"></formFechaHora>
      </v-col>
      <v-col cols="12" sm="6"></v-col>
      
      <v-col cols="6">
        <formButtonSubmitform></formButtonSubmitform>
      </v-col>
      <v-col cols="6">
        <formButtonCancelform @cerrar="cerrar"></formButtonCancelform>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import formarchivo from "@/components/forms/archivos.vue";
import formNumero from "@/components/forms/numero.vue";
import formTexto from "@/components/forms/textos.vue";
import formComboBox from "@/components/forms/combobox.vue";
import formMultiplecombobox from "@/components/forms/multiplecombobox.vue";
import formButtonCancelform from "@/components/forms/buttonCancelform.vue";
import formButtonSubmitform from "@/components/forms/buttonSubmitform.vue";
import formFechaHora from "@/components/forms/fechaHora.vue";
import { obtenerJsonGET, enviarJsonPOST } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    vehiculos: [],
    clasificaciones: [],
  }),
  components: {
    formTexto,
    formComboBox,
    formNumero,
    formMultiplecombobox,
    formButtonCancelform,
    formButtonSubmitform,
    formarchivo,
    formFechaHora,
  },
  mounted: async function () {
    var SendData = {
      text: "",
    };
    var resp = await obtenerJsonGET("product/vehiculos", SendData);
    if (resp) {
      this.vehiculos = resp.data;
    }
    var resp = await obtenerJsonGET("product/clasificaciones", SendData);
    if (resp) {
      this.clasificaciones = resp.data;
    }
  },
  methods: {
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("codigo", this.$refs.codigo.valor);
        fd.append("nombre", this.$refs.nombre.valor);
        fd.append("precio_venta", this.$refs.precio_venta.valor);
        if (this.$refs.clasificacion.valor) {
          fd.append("clasificacion", this.$refs.clasificacion.valor.cod);
        }
        if (this.$refs.compatibilidad.valor) {
          for (let x = 0; x < this.$refs.compatibilidad.valor.length; x++) {
            fd.append(
              "Compatibilidad[]",
              this.$refs.compatibilidad.valor[x].cod
            );
          }
        }
        
        fd.append("destacado", this.$refs.destacado.valor);
        if(this.$refs.imagen.valor){
            fd.append("imagen", this.$refs.imagen.valor[0]);
          }
        var json = await enviarJsonPOST("product/new", fd);
        if (json.error == 0) {
          this.$router.go(-1);
          this.$emit("validarMensaje", json);
        }
      }
    },
    cerrar: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>

