<template>
  <v-container fluid class="vision-page">
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" md="10" lg="8">
        <v-card flat class="transparent">
          <v-card-title class="text-h2 font-weight-light text-center mb-12">
            Nuestra Visión
          </v-card-title>

          <v-row justify="center" class="mb-16">
            <v-col cols="12" md="10">
              <div class="text-h4 font-weight-light text-center vision-statement">
                "Ser líderes en mercado chileno en la asistencia a usuarios finales mediante la tecnología y
                seguimiento de los productos requeridos, creando un impacto positivo en la vida de las personas y el planeta."
              </div>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-card flat class="transparent">
                <v-card-title class="text-h4 font-weight-light text-center mb-6">
                  Nuestro Camino Hacia el Futuro
                </v-card-title>
                <v-card-text class="text-body-1 text-center">
                  En Repuestia, estamos comprometidos con convertir nuestra visión 
                  en realidad. Cada día trabajamos para en implementación tecnhológica y logística. Juntos, estamos construyendo un futuro donde buscar un repuesto sea un trabajo simple y de coto accesible.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VisionPage',
  data: () => ({
  })
}
</script>

<style scoped>
.vision-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}
.vision-statement {
  line-height: 1.6;
  font-style: italic;
}
.vision-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
}
.vision-card:hover {
  transform: translateY(-5px);
}
</style>



<!--
  Nuestra visión es posicionarnos dentro del mercado chileno como un
  líder en la asistencia a usuarios finales mediante la tecnología y
  seguimiento de los productos requeridos.-->