<template>
  <v-container fluid class="vision-page">
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" md="10" lg="8">
        <v-card flat class="transparent">
          <v-card-title class="text-h2 font-weight-light text-center mb-12">
            Nuestra Misión
          </v-card-title>

          <v-row justify="center" class="mb-16">
            <v-col cols="12" md="10">
              <div class="text-h4 font-weight-light text-center vision-statement">
                "Ofrecerles a nuestros clientes la más amplia selección de repuestos automotrices originales y alternativos a los mejores precios posibles y con el mejor servicio en términos de capacidad, profesionalismo y asistencia."
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VisionPage',
  data: () => ({
  })
}
</script>

<style scoped>
.vision-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}
.vision-statement {
  line-height: 1.6;
  font-style: italic;
}
.vision-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
}
.vision-card:hover {
  transform: translateY(-5px);
}
</style>

