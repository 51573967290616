<template>
  <v-container fluid v-if="this.products.length > 0">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <h2 class="text-h5 mb-6 text-center">Productos Destacados</h2>
        <v-window v-model="window" class="elevation-2">
          <v-window-item v-for="product in products" :key="product.codigo">
            <v-card flat @click="confirmaProducto(product.id)">
              <v-row>
                <v-col cols="12" md="6">
                  <v-img
                    :src="product.img ? product.img : '/img/sinfoto.png'"
                    :alt="product.nombre"
                    height="300"
                    contain
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex flex-column justify-center"
                >
                  <v-card-title class="text-h5">
                    {{ product.nombre }}
                  </v-card-title>
                  <v-card-subtitle class="pb-0">
                    {{ product.clasificacion }}<br />
                    Código: {{ product.codigo }}
                    <div class="text-h6">
                      {{ formatoMonedaChile(product.precio_venta) }}
                    </div>
                  </v-card-subtitle>
                  <v-card-text>
                    <lu>
                      <li v-for="mo in product.modelos" :key="mo">
                        {{ mo.nombre }}
                      </li>
                    </lu>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>

        <v-card-actions class="justify-center mt-4">
          <v-btn @click="prev" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="window" class="text-center" mandatory>
            <v-item
              v-for="n in products.length"
              :key="n"
              v-slot="{ active, toggle }"
            >
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn @click="next" icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="dialog" max-width="900" width="80%" persistent>
    <v-layout style="height: 25px">
      <v-system-bar>
        <v-icon
          class="me-2"
          icon="mdi-information-variant"
        ></v-icon>

        <span>Información de producto</span>

        <v-spacer></v-spacer>
        <v-btn class="ms-2" icon="mdi-close" variant="text"
          @click="cerrarProducto()"></v-btn>
      </v-system-bar>
    </v-layout>
    <v-card>
      <v-card-text>
        <v-container>
          <descProducto :id="product"></descProducto>
        </v-container>
      </v-card-text>
      <v-card-action> </v-card-action>
    </v-card>
  </v-dialog>
</template>
    
<script>
import descProducto from "@/components/producto/viewproducto.vue";
import {
  obtenerJsonGET,
  formatoMonedaChile,
} from "@/helpers/funcionesEstandard";

export default {
  name: "FeaturedProducts",
  data: () => ({
    dialog: false,
    window: 0,
    products: [],
    product: null,
    load: false,
    formatoMonedaChile: formatoMonedaChile,
  }),
  components: {
    descProducto,
  },

  mounted() {
    this.startAutoChange();
  },
  beforeDestroy() {
    this.stopAutoChange();
  },
  methods: {
    confirmaProducto: function (id) {
      this.product = id;
      this.dialog = true;
    },
    cerrarProducto: function () {
      this.dialog = false;
    },
    startAutoChange: async function () {
      if (!this.load) {
        var SendData = {};
        var resp = await obtenerJsonGET("product/destacados", SendData);
        console.log(resp);
        if (resp) {
          this.products = resp.data;
        } else {
          this.products = [];
        }
        this.load = true;
      }
      this.intervalId = setInterval(() => {
        this.next();
      }, 5000);
    },
    stopAutoChange() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    next() {
      this.window = (this.window + 1) % this.products.length;
    },
    prev() {
      this.window =
        (this.window - 1 + this.products.length) % this.products.length;
    },
  },
};
</script>