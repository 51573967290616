<template>
  <v-card flat>
    <v-card-title class="d-flex align-center pe-2">
      <v-icon icon="mdi-store"></v-icon> &nbsp; Productos

      <v-spacer></v-spacer>

      <v-btn icon @click="nuevoproducto">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        density="compact"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        flat
        hide-details
        single-line
        clearable="true"
      ></v-text-field>
    </v-card-title>

    <v-divider></v-divider>
    <v-data-table-server
      v-model:search="search"
      :items="items"
      :items-length="totalItems"
      :headers="headers"
      @update:options="loadItems"
      class="pa-2"
    >
      <template v-slot:header.precio_venta>
        <div class="text-end">Precio Venta</div>
      </template>
      <template v-slot:item.precio_venta="{ item }">
        <div class="text-end">{{ formatoMonedaChile(item.precio_venta) }}</div>
      </template>

      <template v-slot:item.img="{ item }">
        <v-img
          :src="
            item.img != ''
              ? item.img
              : '/img/sinfoto.png'
          "
          width="30"
        ></v-img>
      </template>

      <template v-slot:item.image="{ item }">
        <v-card class="my-2" elevation="2" rounded>
          <v-img
            :src="`https://cdn.vuetifyjs.com/docs/images/graphics/gpus/${item.image}`"
            height="64"
            cover
          ></v-img>
        </v-card>
      </template>

      <!--template v-slot:item.rating="{ item }">
        <v-rating
          :model-value="item.rating"
          color="orange-darken-2"
          density="compact"
          size="small"
          readonly
        ></v-rating>
      </template>

      <template v-slot:item.stock="{ item }">
        <div class="text-end">
          <v-chip
            :color="item.stock ? 'green' : 'red'"
            :text="item.stock ? 'In stock' : 'Out of stock'"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </div>
      </template-->
    </v-data-table-server>
  </v-card>
</template>

<script>
import {
  obtenerJsonGET,
  formatoMonedaChile,
} from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    search: "",
    totalItems: 0,
    items: [],
    itemsPerPage: 10,
    page: 1,
    formatoMonedaChile: formatoMonedaChile,
    headers: [
      {
        title: "Imagen",
        align: "start",
        sortable: false,
        key: "img",
      },
      {
        title: "Código",
        align: "center",
        sortable: true,
        key: "codigo",
      },
      {
        title: "Nombre",
        align: "nombre",
        sortable: true,
        key: "nombre",
      },
      {
        title: "Clasificación",
        align: "left",
        sortable: true,
        key: "clasificacion",
      },
      {
        title: "Precio",
        align: "right",
        sortable: true,
        key: "precio_venta",
      },
    ],
  }),
  mounted: async function () {
    this.findDB({
      itemsPerPage: this.itemsPerPage,
      page: this.page,
      search: "",
    });
  },
  methods: {
    loadItems: async function (obj) {
      console.log(arguments);
      if (obj.search) {
        if (obj.search.length > 2) {
          // Clear the previous timeout if it exists
          if (this.timeout) {
            clearTimeout(this.timeout);
          }

          // Set a new timeout
          this.timeout = setTimeout(() => {
            this.findDB(obj);
          }, 500);
        }
      } else {
        this.findDB(obj);
      }
    },
    findDB: async function (obj) {
      let sortbyname = "nombre";
      let sortbydir = "asc";
      if (obj.sortBy) {
        if (obj.sortBy.length) {
          sortbyname = obj.sortBy[0].key;
          sortbydir = obj.sortBy[0].order;
        }
      }
      var SendData = {
        search: obj.search ? obj.search : "",
        itemsPerPage: obj.itemsPerPage,
        page: obj.page,
        sortbyname: sortbyname,
        sortbydir: sortbydir,
      };
      var resp = await obtenerJsonGET("product/listado", SendData);
      if (resp) {
        this.totalItems = resp.registros;
        console.log(resp.data);
        this.items = resp.data;
      }
    },
    nuevoproducto: function () {
      this.$router.push("/nuevo/producto");
    },
  },
};
</script>

<style>
</style>