<template>
  <v-row>
    <v-col cols="12" sm="4">
      <v-row>
        <v-col cols="8" class="text-end">
          <formNumero
            labelSet="Cantidad"
            :clearable="false"
            :valorMinimoSet="1"
            :valorMaximoSet="10"
            :valorSet="1"
            @setearValor="SetCentidad"
          ></formNumero>
        </v-col>
        <v-col cols="4" class="text-start">
          <v-btn prepend-icon="mdi-plus" @click="agregarCarro" color="success"
            ><v-icon>mdi-cart</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-img
        width="100%"
        :src="producto.img ? producto.img : '/img/sinfoto.png'"
      ></v-img>
    </v-col>
    <v-col cols="12" sm="8">
      <v-row>
        <v-col cols="12">
          <div>
            Código: <span class="text-h5">{{ producto.codigo }}</span>
          </div>
          <div>Producto:</div>
          <div class="text-h4">{{ producto.nombre }}</div>
          <div>
            Clasificación:<br />
            <span class="text-h6">{{ clasificacion.clasificacion }}</span>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Compatibilidades:</div>
          <div v-for="com in compatibilidad" :key="com">
            - {{ com.versiones }}
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <v-dialog v-model="dialog" max-width="400" width="400" persistent>
    <v-card>
      <v-card-text>
        <v-container>
          ¿Está seguro de agregar {{ cantidadCarro }} {{ producto.nombre
          }}{{ cantidadCarro > 1 ? "s" : "" }} al carro de compras?
        </v-container>
      </v-card-text>
      <v-card-action class="text-end">
        <v-row>
          <v-col cols="6" class="text-center">
            <v-btn
              icon="mdi-check"
              variant="text"
              @click="dialog = false"
            ></v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              icon="mdi-cancel"
              variant="text"
              @click="dialog = false"
            ></v-btn>
          </v-col>
        </v-row>
      </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
import formNumero from "@/components/forms/numero.vue";

import {
  obtenerJsonGET,
  formatoMonedaChile,
} from "@/helpers/funcionesEstandard";
export default {
  data: () => ({
    cantidadCarro: 1,
    producto: {},
    clasificacion: {},
    compatibilidad: [],
    dialog: false,
  }),
  props: {
    id: Number,
  },
  components: {
    formNumero,
  },
  mounted: async function () {
    var SendData = {
      producto_id: this.id,
    };
    var resp = await obtenerJsonGET("product/obtener", SendData);
    if (resp) {
      this.producto = resp.data.producto;
      this.clasificacion = resp.data.clasificacion;
      this.compatibilidad = resp.data.compatibilidad;
      console.log(resp.data.producto);
    }
  },
  methods: {
    SetCentidad: function (num) {
      this.cantidadCarro = num;
    },
    agregarCarro: function () {
      if (this.cantidadCarro > 0 &&  this.cantidadCarro <=10) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style>
</style>