<template>
  <v-container fluid class="vision-container">
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" md="10" lg="8">
        <v-card flat class="transparent">
          <v-card-title class="text-h2 font-weight-light text-center mb-12">
            Quienes Somos
          </v-card-title>
          
          <v-row justify="center" class="mb-12">
            <v-col cols="12" md="8">
              <p class="text-h6 font-weight-light text-center">
                Repuestia es un proveedor líder de repuestos y accesorios para automóviles, que ofrece una amplia gama de productos de alta calidad para diversos modelos de vehículos. 
                <br>Con un enfoque en la satisfacción del cliente, Repuestia asegura la disponibilidad de componentes de suspensión, dirección y frenos, así como también bujías, filtros, baterías, entre otros productos, todos ellos de primer nivel y a precios muy competitivos.
                 <br>Disponemos de varios canales de venta asistida y autogestionada. 
                 <br>Manténgase a la vanguardia con las últimas ofertas de Repuestia en mantenimiento y reparación de automóviles, respaldadas por nuestro compromiso con la excelencia en el servicio y la calidad del producto.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-for="(pillar, index) in pillars" :key="index" cols="12" md="4">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 8 : 2"
                  :class="{ 'on-hover': hover }"
                  height="100%"
                >
                  <v-card-title class="text-h6 justify-center">
                    {{ pillar.title }}
                  </v-card-title>
                  <v-card-text class="text-center">
                    {{ pillar.description }}
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-icon large :color="pillar.color">{{ pillar.icon }}</v-icon>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-12">
            <v-col cols="12" md="8">
              <v-card flat class="transparent">
                <v-card-title class="text-h4 font-weight-light text-center mb-6">
                  Nuestro Compromiso
                </v-card-title>
                <v-card-text class="text-body-1 text-center">
                  En Repuestia, trabajamos incansablemente para posicionarnos dentro del mercado chileno como un líder en la asistencia a usuarios finales 
                  mediante la tecnología y seguimiento de los productos requeridos, siempre con la mirada
                  puesta en un futuro mejor para nuestros clientes y la sociedad.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
          <v-expand-transition>
                        <v-row v-if="showTeam" class="mt-4">
                          <v-col
                            v-for="(member, index) in team"
                            :key="index"
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-card flat class="text-center">
                              <v-avatar size="100" class="mb-3">
                                <v-img :src="member.avatar"></v-img>
                              </v-avatar>
                              <div class="text-h6">{{ member.name }}</div>
                              <div class="text-subtitle-1 text--secondary">{{ member.position }}</div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'QuinesSomos',
  data: () => ({
    showTeam: true,
    pillars: [
      {
        title: 'Innovación',
        description: 'Buscamos constantemente nuevas formas de mejorar y revolucionar nuestra industria.',
        icon: 'mdi-lightbulb-on',
        color: 'amber'
      },
      {
        title: 'Sostenibilidad',
        description: 'Nos comprometemos a operar de manera responsable y a proteger el medio ambiente.',
        icon: 'mdi-leaf',
        color: 'green'
      },
      {
        title: 'Colaboración',
        description: 'Fomentamos un ambiente de trabajo en equipo y partnerships estratégicos.',
        icon: 'mdi-account-group',
        color: 'blue'
      }
    ],
                team: [
                  { name: 'Raúl Castro', position: '', avatar: 'ruta/a/imagen1.jpg' },
                  { name: 'Reynaldo Machuca', position: '', avatar: 'ruta/a/imagen2.jpg' },
                  { name: 'Marco Bustamante', position: '', avatar: 'ruta/a/imagen3.jpg' },
                ],
  })
}
</script>

<style scoped>
.vision-container {
  min-height: 100vh;
  background-color: #f7f7f7;
}
.v-card {
  transition: opacity .4s ease-in-out;
}
.v-card:not(.on-hover) {
  opacity: 0.8;
}
</style>




<!--
Repuestia es un proveedor líder de repuestos y accesorios para automóviles, que ofrece una amplia gama de productos de alta calidad para diversos modelos de vehículos. Con un enfoque en la satisfacción del cliente, Repuestia asegura la disponibilidad de componentes de suspensión, dirección y frenos, así como también bujías, filtros, baterías, entre otros productos, todos ellos de primer nivel y a precios muy competitivos. <br>
            Disponemos de varios canales de venta asistida y autogestionada. Manténgase a la vanguardia con las últimas ofertas de Repuestia en mantenimiento y reparación de automóviles, respaldadas por nuestro compromiso con la excelencia en el servicio y la calidad del producto..-->


